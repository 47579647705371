import { ExistentFileModal as UploadExistentFileModal } from "@components/modals/ExistentFileModal";
import { RadioNames, TRadioButton } from "@components/RadioButton";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef, useState } from "react";
import { SelectOption, SelectType, StepsProps } from "./types";
import {
  getDocuments,
  showDocument,
} from "@redux/documents/thunks/documentsThunk";
import { getExistentFilesValuesByFolder } from "@redux/files/thunks/fileThunk";

import { Asterisk } from "./styled/Asterisk";
import ButtonUI from "@components/Button";
import { CaptionSpan } from "./styled/CaptionSpan";
import Chip from "@components/Chip";
import DocumentsSection from "@sections/filesManagement/DocumentsSection";
import FilesModal from "@components/FilesModal";
import { Form } from "react-bootstrap";
import { FormTitle } from "./styled/FormTitle";
import { GenericModal } from "@components/Modal";
import { IIdentifiedFile } from "@models/files/File.type";
import { InputSubtitle } from "./styled/InputSubtitle";
import { InputText } from "./styled/InputText";
import LexicalWysWyg from "@components/LexicalWysWyg/LexicalWysWyg";
import { MAX_FILE_SIZE } from "../../utils/constants";
import { ProcessesContext } from "./context/ProcessesContext";
import Select from "react-select";
import SimpleSearch from "@components/Search/SimpleSearch";
import { StepContainer } from "./styled/StepContainer";
import { StepFormList } from "./styled/StepFormList";
import { StyledSelect } from "./styled/StyledSelect";
import { TglBtn } from "./styled/TglBtn";
import { ToggleContainer } from "./styled/ToggleContainer";
import { UploadFile } from "@components/AdminHeader";
import { createToast } from "@helpers/createToast";
import { customStylesSelectStep } from "./styled/customStylesSelectStep";
import { isEqual } from "lodash";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { setCurrentResource } from "@redux/documents/documentsSlice";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import useDocumentsContext from "@hooks/useDocumentsContext";
import { addToast, removeToast } from "@redux/toasts/slices/toastsSlice";
import { EToastTypes } from "@models/toast/Toast.type";

export const Steps: React.FC<StepsProps> = ({
  onClose,
  DropdownIndicatorChevron,
  onSubmit,
  errors,
  index,
  setValue,
  getValues,
  watch,
  values,
}) => {
  const [isStepFocused, setStepFocused] = useState(false);
  const [isFormFocused, setFormFocused] = useState(false);
  const [requiresApproval, setRequiresApproval] = useState<boolean>(true);
  const [isNameFocused, setNameFocused] = useState<boolean>(false);
  const [isOpenAttachFilesModal, setIsOpenAttachFilesModal] = useState(false);
  const dispatch = useAppDispatch();
  const selectStepRef = useRef<any>(null);
  const selectFormRef = useRef<any>(null);
  const stepContainerRef = useRef<any>(null);
  const { showAllForms, state: processState } = useContext(ProcessesContext);

  const { searchDocument, setSearchDocument } = useDocumentsContext();
  const {
    documents: { current: currentResource },
  } = useAppSelector((state) => state);

  const handleShowAllForms = () => {
    showAllForms();
  };

  const transformedFormOptions = processState.dataForms.map(
    (step: SelectType) => ({
      label: step.title,
      value: step.id.toString(),
    }),
  );

  const handleSelectTypeClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (selectStepRef.current) {
      if (isNotNilOrEmpty(selectStepRef.current.state.focusedOption)) {
        selectStepRef.current.onMenuClose();
        return;
      }

      selectStepRef.current.focus();
      selectStepRef.current.onMenuOpen();
    }
  };

  const handleSelectFormClick = (e: any) => {
    e.stopPropagation();
    if (isNotNilOrEmpty(selectFormRef.current.state.focusedOption)) {
      if (selectFormRef.current) {
        selectFormRef.current.onMenuClose();
      }
      return;
    }

    if (selectFormRef.current) {
      selectFormRef.current.focus();
      selectFormRef.current.onMenuOpen();
    }
  };

  const handleOutSelectStepClick = () => {
    if (selectStepRef.current) {
      selectStepRef.current.onMenuClose();
    }
    if (selectFormRef.current) {
      selectFormRef.current.onMenuClose();
    }
  };

  const stepOptions: SelectOption[] = [
    { value: "basic", label: "Basic Step" },
    { value: "form", label: "Form Step" },
  ];

  const handleToggleRequiresApproval = (): void => {
    setRequiresApproval(!requiresApproval);
    setValue(`steps[${index}].approval_required`, !requiresApproval);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectStepRef.current &&
        selectStepRef.current.select.controlRef &&
        !selectStepRef.current.select.controlRef.contains(event.target)
      ) {
        selectStepRef.current.onMenuClose();
      }
    };

    setRequiresApproval(
      getValues(`steps[${index}].approval_required`) || false,
    );

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeStepType = (val: any) => {
    setValue(`steps[${index}].stepType`, val);
  };

  const handleChangeFormType = (val: any) => {
    setValue(`steps[${index}].formType`, val);
  };

  const handleChangeContent = (val: any) => {
    setValue(`steps[${index}].content`, val);
  };

  const handleChangeName = (val: any) => {
    setValue(`steps[${index}].name`, val.target.value);
  };

  const watchFormType = watch(`steps[${index}].stepType`);

  useEffect(() => {
    handleShowAllForms();

    const element = document.getElementById(`steps[${index}].name`) as
      | HTMLInputElement
      | HTMLTextAreaElement;
    if (element) {
      element.value = getValues(`steps[${index}].name`);
    }
  }, []);

  const handleKeyPressType = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleSelectTypeClick(e as any);
    }
  };

  const handleKeyPressForm = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleSelectFormClick(e as any);
    }
  };

  const handleFileManagementCheckbox = (document: {
    id: number;
    name: string;
  }) => {
    const currentDocuments = getValues(`steps[${index}].documents`) || [];
    let updatedDocuments;
    const isChecked = currentDocuments.find(
      (doc: any) => doc.id === document.id,
    );
    if (!isChecked) {
      updatedDocuments = [...currentDocuments, document];
    } else {
      updatedDocuments = currentDocuments.filter(
        (doc: any) => doc.id !== document.id,
      );
    }

    setValue(`steps[${index}].documents`, updatedDocuments);
  };

  const onArrowButtonClick = () => {
    dispatch(
      getDocuments({ parentId: currentResource?.parent_id || undefined }),
    );
    if (!currentResource?.parent_id) {
      dispatch(
        setCurrentResource({
          data: {
            parent_id: undefined,
            id: null,
            name: null,
          },
        }),
      );
    } else {
      dispatch(showDocument({ id: currentResource?.parent_id || undefined }));
    }
  };

  const handleOnType = (value: string) => {
    setSearchDocument({
      query: value,
      only_files: searchDocument !== "",
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  const handleOnClear = () => {
    setSearchDocument({
      query: "",
      only_files: false,
      parentId: currentResource?.id ? currentResource?.id : undefined,
    });
  };

  const id = useRef<number>(0);
  const [showFilesModal, setShowFilesModal] = useState<boolean>(false);

  const { filesToUpload, setFilesToUpload, uploadFiles } =
    useDocumentsContext();

  const handleFileLoad = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const fileArray = target.files
      ? Array.from(target.files).map((file: File): any => ({
          // eslint-disable-next-line no-plusplus
          id: ++id.current,
          file,
          status: file.size / 1000 <= MAX_FILE_SIZE,
        }))
      : [];

    if (fileArray.some((file) => file.file.size === 0)) {
      setShowFilesModal(false);
      createToast(
        "Folders not supported. Please choose an individual file.",
        "danger",
        dispatch,
      );
    } else {
      setFilesToUpload([...filesToUpload, ...fileArray]);
      setShowFilesModal(true);
    }
  };

  const [existentFiles, setExistentFiles] = useState<Array<IIdentifiedFile>>(
    [],
  );
  const [replaceNameFiles, setReplaceNameFiles] = useState<Array<string>>([]);
  const [uploadExistentFiles, setUploadExistentFiles] = useState<
    Array<IIdentifiedFile>
  >([]);
  const [showExistentFileModal, setShowExistentFileModal] =
    useState<boolean>(false);
  const [indexCurrentExistentFile, setIndexCurrentExistentFile] =
    useState<number>(0);

  const {
    documents,
    file: { dropElementId },
  } = useAppSelector((state) => state);

  const handleUploadFiles = ({
    parentId,
  }: {
    parentId: number | null | undefined;
  }) => {
    dispatch(
      addToast({
        id: "filesUploading",
        type: EToastTypes.WARNING,
        text: `<div class="d-flex">
          <div class="loader"></div>
          <span> Uploaded 0 of ${filesToUpload.length}  </span>
        </div>`,
        autohide: false,
        withClose: true,
      }),
    );
    uploadFiles({ parentId })
      .then((documentsUploaded) => {
        const elementsCount = filesToUpload.length;
        const plural = elementsCount > 1;
        const elementsText = plural ? "elements" : "element";
        const verbText = plural ? "have" : "has";
        const text = `<strong>Elements uploaded</strong><br>${elementsCount} ${elementsText} ${verbText} been uploaded successfully!`;
        createToast(text, "success", dispatch);
        documentsUploaded.forEach((document) => {
          handleFileManagementCheckbox(document);
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch(removeToast("filesUploading"));
        createToast(
          `We have an error uploading the folder`,
          "danger",
          dispatch,
        );
      });
  };
  const handleSubmitFiles = async (files: Array<IIdentifiedFile>) => {
    const parentId = dropElementId || documents?.current?.id;

    if (existentFiles.length) {
      files.forEach((file) => {
        const f = file;
        if (replaceNameFiles.includes(f.file.name)) {
          f.action = RadioNames.Replace;
        }
        return f;
      });
      setFilesToUpload(files);
      setReplaceNameFiles([]);
    }
    await handleUploadFiles({ parentId });
  };

  const handleFileUpload = () => {
    const parentId = dropElementId ?? documents?.current?.id;
    const uploadNameFiles = filesToUpload.map((o) => o.file.name.toLowerCase());
    dispatch(
      getExistentFilesValuesByFolder({ parentId, values: uploadNameFiles }),
    ).then(async (resp) => {
      const respExistentFilesValues = resp.payload;

      if (!respExistentFilesValues?.includes(true)) {
        await handleUploadFiles({ parentId });

        setShowFilesModal(false);
      } else {
        const existFiles = filesToUpload.filter(
          (_, idx) => respExistentFilesValues[idx],
        );
        setExistentFiles(existFiles);
        setUploadExistentFiles(filesToUpload);
        setShowFilesModal(false);
        if (existFiles.length) setShowExistentFileModal(true);
      }
    });
  };

  const onSubmitExistentFileModal = (opt: TRadioButton) => {
    if (isEqual(opt.name, RadioNames.Replace)) {
      const currentExistentFileName =
        existentFiles[indexCurrentExistentFile]?.file?.name;
      setReplaceNameFiles((prev) => [...prev, currentExistentFileName]);
    }
  };

  const onCancelExistentFileModal = () => {
    const currentExistentFileName =
      existentFiles[indexCurrentExistentFile]?.file?.name;
    const uploadFilesExistents = uploadExistentFiles.filter(
      (i) => i.file.name !== currentExistentFileName,
    );
    setUploadExistentFiles(uploadFilesExistents);
  };

  return (
    <StepContainer
      onClick={handleOutSelectStepClick}
      className="shadow"
      ref={stepContainerRef}
    >
      <UploadExistentFileModal
        title={`Existent File: "${existentFiles[indexCurrentExistentFile]?.file?.name}"`}
        onExited={async () => {
          if (indexCurrentExistentFile + 1 >= existentFiles.length) {
            setIndexCurrentExistentFile(0);
            await handleSubmitFiles(uploadExistentFiles);
          }
        }}
        onFinish={() => {
          if (indexCurrentExistentFile + 1 < existentFiles.length) {
            setIndexCurrentExistentFile((prev) => prev + 1);
          } else {
            setShowExistentFileModal(false);
          }
        }}
        onSubmit={onSubmitExistentFileModal}
        onCancel={onCancelExistentFileModal}
        isVisible={showExistentFileModal}
      />
      <FilesModal
        isLoading={false}
        show={showFilesModal}
        setShow={setShowFilesModal}
        {...{
          handleFileLoad,
          handleFileUpload,
        }}
      />

      <GenericModal
        container={stepContainerRef.current}
        title={currentResource?.name || "File Management"}
        subtitle={currentResource?.path || ""}
        onConfirmButton={() => {
          setIsOpenAttachFilesModal(false);
        }}
        onCancelButton={() => {
          setIsOpenAttachFilesModal(false);
        }}
        show={isOpenAttachFilesModal}
        confirmModalText="Attach"
        withCancelButton={false}
        withArrowButton={currentResource?.parent_id !== undefined}
        onArrowButtonClick={() => onArrowButtonClick()}
        disabledConfirmButton={
          watch(`steps[${index}].documents`) === undefined ||
          watch(`steps[${index}].documents`).length === 0
        }
        search={
          <SimpleSearch
            onType={handleOnType}
            onClear={handleOnClear}
            value={searchDocument}
            placeholder="Search files"
            isFetching={false}
            borderStyle
          />
        }
        otherButton={
          <UploadFile
            className="border-0"
            handleFileLoad={handleFileLoad}
            size="big"
            value="+ Upload Files"
            hasIcon={false}
          />
        }
      >
        <DocumentsSection
          show="both"
          withCheckbox
          onClickCheckbox={(document: { id: number; name: string }) =>
            handleFileManagementCheckbox(document)
          }
          documentsSelected={watch(`steps[${index}].documents`)}
        />
      </GenericModal>
      <div
        onClick={onClose}
        onKeyDown={onClose}
        role="button"
        tabIndex={0}
        onMouseDown={(event) => event.preventDefault()}
      >
        <svg
          className="c-process-close-icon"
          viewBox="0 0 32 32"
          width="28"
          height="28"
          stroke="currentColor"
          fill="currentColor"
        >
          <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4z" />
        </svg>
      </div>
      <svg
        className="c-process-drag-icon"
        viewBox="0 0 32 32"
        width="28"
        height="28"
        stroke="currentColor"
        fill="currentColor"
      >
        <path
          d="M15.3 0.3a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L17 3.4V11a1 1 0 0 1-2 0V3.4L12.7 5.7a1 1 0 1 1-1.4-1.4l4-4zM16 20a1 1 0 0 1 1 1v7.6l2.3-2.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4L15 28.6V21A1 1 0 0 1 16 20zM0.3 16.7a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.4 1.4L3.4 15H11a1 1 0 0 1 0 2H3.4l2.3 2.3a1 1 0 0 1-1.4 1.4l-4-4zM20 16a1 1 0 0 1 1-1h7.6l-2.3-2.3a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4L28.6 17H21A1 1 0 0 1 20 16z"
          fillRule="evenodd"
        />
      </svg>
      <StepFormList>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3 w-100">
            <InputSubtitle focused={isStepFocused}>
              Type<Asterisk>*</Asterisk>
            </InputSubtitle>
            <StyledSelect
              $error={
                isNotNilOrEmpty(errors) && Array.isArray(errors.steps)
                  ? !!errors.steps?.[index]?.stepType
                  : false
              }
            >
              <div
                onClick={handleSelectTypeClick}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyPressType}
              >
                <Select
                  name="stepType"
                  ref={selectStepRef}
                  options={stepOptions}
                  classNamePrefix="react-select"
                  styles={customStylesSelectStep}
                  components={{
                    DropdownIndicator: DropdownIndicatorChevron,
                  }}
                  isSearchable={false}
                  isClearable={false}
                  onFocus={() => setStepFocused(true)}
                  onBlur={() => setStepFocused(false)}
                  placeholder="Select a type of step"
                  value={getValues(`steps[${index}].stepType`)}
                  onChange={(val) => {
                    handleChangeStepType(val);
                  }}
                />
              </div>
              {isNotNilOrEmpty(errors) &&
                Array.isArray(errors.steps) &&
                errors.steps[index]?.stepType && (
                  <span className="error-message">
                    {errors.steps[index]?.stepType?.message}
                  </span>
                )}
            </StyledSelect>
          </Form.Group>

          {watchFormType?.value === "form" && (
            <>
              <Form.Group className="mb-3 w-100">
                <InputSubtitle focused={isFormFocused}>
                  Form<Asterisk>*</Asterisk>
                </InputSubtitle>
                <StyledSelect
                  $error={
                    isNotNilOrEmpty(errors) && Array.isArray(errors.steps)
                      ? !!errors?.steps[index]?.formType
                      : false
                  }
                >
                  <div
                    onKeyDown={handleKeyPressForm}
                    onClick={handleSelectFormClick}
                    role="button"
                    tabIndex={0}
                  >
                    <Select
                      ref={selectFormRef}
                      options={transformedFormOptions}
                      value={getValues(`steps[${index}].formType`)}
                      onChange={(val) => {
                        handleChangeFormType(val);
                      }}
                      classNamePrefix="react-select"
                      styles={customStylesSelectStep}
                      components={{
                        DropdownIndicator: DropdownIndicatorChevron,
                      }}
                      isSearchable={false}
                      isClearable={false}
                      onFocus={() => setFormFocused(true)}
                      onBlur={() => setFormFocused(false)}
                      placeholder="Select a form type"
                    />
                  </div>

                  {isNotNilOrEmpty(errors) &&
                    Array.isArray(errors.steps) &&
                    errors?.steps[index]?.formType && (
                      <span className="error-message">
                        {isNotNilOrEmpty(errors) &&
                          errors?.steps[index]?.formType?.message}
                      </span>
                    )}
                </StyledSelect>
              </Form.Group>

              <ToggleContainer>
                <CaptionSpan>Requires approval</CaptionSpan>

                <div
                  className={`border border-3 rounded-pill mb-4 ${requiresApproval ? "toggle-border-true" : "toggle-border-false"}`}
                  style={{
                    width: "60px",
                    transform: "scale(0.5)",
                  }}
                >
                  <TglBtn
                    value={requiresApproval}
                    onToggle={handleToggleRequiresApproval}
                  />
                </div>
              </ToggleContainer>
            </>
          )}

          {watchFormType?.value === "basic" && (
            <>
              <FormTitle>Step Content</FormTitle>

              <Form.Group className="mb-3" controlId="formTitle">
                <InputSubtitle focused={isNameFocused}>
                  Name <Asterisk>*</Asterisk>
                </InputSubtitle>
                <InputText $error={!!errors.name}>
                  <input
                    id={`steps[${index}].name`}
                    type="text"
                    placeholder="Enter a name"
                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                    onFocus={() => setNameFocused(true)}
                    onBlur={() => setNameFocused(false)}
                    onChange={(val) => {
                      handleChangeName(val);
                    }}
                  />

                  {isNotNilOrEmpty(errors) &&
                    Array.isArray(errors.steps) &&
                    errors?.steps[index]?.name && (
                      <span className="error-message">
                        {isNotNilOrEmpty(errors) &&
                          errors?.steps[index]?.name?.message}
                      </span>
                    )}
                </InputText>
              </Form.Group>
              <Form.Group className="mb-1" controlId="formTitle">
                <InputSubtitle>
                  Content <Asterisk>*</Asterisk>
                </InputSubtitle>
                <LexicalWysWyg
                  onChange={handleChangeContent}
                  initialValue={values.content || ""}
                />
                {isNotNilOrEmpty(errors) &&
                  Array.isArray(errors.steps) &&
                  errors?.steps[index]?.content && (
                    <span className="error-message">
                      {isNotNilOrEmpty(errors) &&
                        errors?.steps[index]?.content?.message}
                    </span>
                  )}
              </Form.Group>

              <FormTitle>Attachments</FormTitle>
              <div className="row">
                <div className="col-3">
                  <ButtonUI
                    value="Attach file"
                    icon={{
                      icon: "attachment",
                      size: 20,
                      position: "right",
                    }}
                    onClick={() => setIsOpenAttachFilesModal(true)}
                  />
                </div>
                {watch(`steps[${index}].documents`) &&
                  watch(`steps[${index}].documents`).map((doc: any) => (
                    <div className="col-3 my-2">
                      <Chip
                        name={doc.name}
                        onClose={() => handleFileManagementCheckbox(doc)}
                        size="small"
                        icon="draft"
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </Form>
      </StepFormList>
    </StepContainer>
  );
};
