/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const generateImageThumbnail = (
  file: File | Blob,
  maxWidth?: number,
  maxHeight?: number,
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const { width } = img;
      const { height } = img;

      // Configuración de valores predeterminados si no se proporciona maxWidth o maxHeight
      const aspectRatio = width / height;
      if (aspectRatio > 1) {
        maxWidth = 110;
      } else {
        maxHeight = 110;
      }

      // Calcular las dimensiones manteniendo el aspecto de la imagen
      if (maxWidth && !maxHeight) {
        maxHeight = Math.round(maxWidth / aspectRatio);
      } else if (!maxWidth && maxHeight) {
        maxWidth = Math.round(maxHeight * aspectRatio);
      }

      // Crear el canvas con las nuevas dimensiones
      const canvas = document.createElement("canvas");
      canvas.width = maxWidth!;
      canvas.height = maxHeight!;
      const ctx = canvas.getContext("2d");

      if (
        ctx &&
        typeof maxWidth === "number" &&
        typeof maxHeight === "number"
      ) {
        // Dibujar la imagen redimensionada en el canvas
        ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

        // Convertir el canvas a un Blob
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(
              new Error("Error al crear el Blob de la imagen redimensionada."),
            );
          }
        }, "image/png");
      } else {
        reject(new Error("No se pudo obtener el contexto del canvas."));
      }
    };

    img.onerror = (err) => reject(err);

    // Leer el archivo como URL para cargarlo en el elemento <img>
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        img.src = e.target.result as string;
      } else {
        reject(new Error("Error al leer el archivo de imagen."));
      }
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
};

type InputSource = string | File;

// Función auxiliar para obtener el ArrayBuffer desde una URL o un File
const getArrayBuffer = (input: InputSource): Promise<ArrayBuffer> | string => {
  if (typeof input === "string") {
    return input;
  }
  if (input instanceof File) {
    // Si la entrada es un archivo, usamos FileReader
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(input);
    });
  }
  return Promise.reject(new Error("Tipo de entrada no válido."));
};

export const generatePdfThumbnail = async (
  input: InputSource,
  maxWidth?: number,
  maxHeight?: number,
): Promise<Blob> => {
  return new Promise(async (resolve, reject) => {
    try {
      const arrayBuffer = await getArrayBuffer(input);
      const loadingTask = pdfjs.getDocument(arrayBuffer);

      const pdf = await loadingTask.promise; // Esperamos la carga del documento
      const page = await pdf.getPage(1); // Obtenemos la primera página
      const viewport = page.getViewport({ scale: 0.4 });

      // Crear el canvas para renderizar la miniatura
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        await page.render({
          canvasContext: ctx,
          viewport,
        }).promise;

        // Convertimos el canvas a un Blob
        canvas.toBlob(async (blob) => {
          if (blob) {
            try {
              const thumbnail = await generateImageThumbnail(
                blob,
                maxWidth,
                maxHeight,
              );
              resolve(thumbnail);
            } catch (err) {
              reject(new Error(`Error al generar la miniatura de la imagen`));
            }
          } else {
            reject(
              new Error("Error al crear el Blob de la miniatura del PDF."),
            );
          }
        }, "image/png");
      } else {
        reject(new Error("No se pudo obtener el contexto del canvas."));
      }
    } catch (err) {
      reject(new Error(`Error al generar la miniatura del PDF`));
    }
  });
};

const generateThumbnail = async ({
  file,
  maxWidth,
  maxHeight,
}: {
  file: File;
  maxWidth?: number;
  maxHeight?: number;
}): Promise<Blob | undefined> => {
  let thumbnail: Blob | undefined;

  switch (true) {
    case file.type.startsWith("image/"):
      thumbnail = await generateImageThumbnail(file, maxWidth, maxHeight);
      break;
    case file.type === "application/pdf":
      thumbnail = await generatePdfThumbnail(file, maxWidth, maxHeight);
      break;
    default:
      thumbnail = undefined;
  }
  return thumbnail;
};

export default generateThumbnail;
