/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable new-cap */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AdminHttpProvider, { baseURL, getCookie } from "@api/AdminHttpProvider";
import { IFile, IFolder } from "@models/files/File.type";

import HttpProvider from "@api/HttpProvider";
import { TRadioButtonName } from "@components/RadioButton";
import _ from "lodash";
import axios from "axios";
import mime from "mime-types";

const api = new HttpProvider();
const adminApi = new AdminHttpProvider();

function lookupMimeType(extension: string) {
  // Si no se encuentra en los personalizados, setea un mime-type generico
  return mime.lookup(extension) || "application/octet-stream";
}

export default class FileController {
  getAll() {
    return adminApi.request("get", `api/admin/documents`);
  }

  index(parentId: number | null) {
    return adminApi.request("get", `api/admin/documents/${parentId}`);
  }

  getFoldersId() {
    return adminApi.request("get", `api/admin/documents/folderids`);
  }

  static addFile = async ({
    file,
    parentId,
    action,
    thumbnail,
  }: {
    file: File;
    parentId: string;
    action: TRadioButtonName;
    thumbnail?: Blob;
  }) => {
    const token =
      getCookie("access_token") || localStorage.getItem("access_token");

    const fileExtension = file.name.split(".")[file.name.split(".").length - 1];
    const fileMimeType = lookupMimeType(fileExtension);
    const thumbnailMimeType = thumbnail?.type || "";
    const thumbnailExtension = thumbnailMimeType?.split("/")[1] || "";
    const bodyFormData = new FormData();
    const fileName = file.name
      .split(".")
      .slice(0, file.name.split(".").length - 1)
      .join(".");
    bodyFormData.append("parent_id", parentId || "");
    bodyFormData.append("action", action || "keep_both");
    bodyFormData.append("name", fileName);
    bodyFormData.append("document_tags[]", "");
    bodyFormData.append("file[original_name]", fileName);
    bodyFormData.append("file[mime_type]", fileMimeType);
    bodyFormData.append("file[extension]", fileExtension);
    bodyFormData.append("file[size]", file.size.toString());
    if (thumbnail) {
      bodyFormData.append("file[thumbnail][mime_type]", thumbnailMimeType);
      bodyFormData.append("file[thumbnail][extension]", thumbnailExtension);
    }

    const { data } = await axios.post(
      `${baseURL}/api/v2/admin/documents/files`,
      bodyFormData,
      {
        timeout: 360000,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": `multipart/form-data`,
        },
      },
    );

    await Promise.all([
      axios.put(data.data.file.temporary_upload_url, file, {
        headers: {
          "Content-Type": fileMimeType,
        },
      }),
      ...(thumbnail
        ? [
            axios.put(
              data.data.file.temporary_thumbnail_upload_url,
              thumbnail,
              {
                headers: {
                  "Content-Type": thumbnailMimeType,
                },
              },
            ),
          ]
        : []),
    ]);

    await Promise.all([
      adminApi.request(
        "patch",
        `${baseURL}/api/v2/admin/documents/${data.data.id}/uploaded`,
      ),
      ...(thumbnail
        ? [
            adminApi.request(
              "patch",
              `${baseURL}/api/v2/admin/documents/${data.data.id}/thumbnail/uploaded`,
            ),
          ]
        : []),
    ]);

    return data;
  };

  addFolder = async (folder: IFolder) => {
    return adminApi.request("post", "api/admin/documents", folder);
  };

  delete(idFile: number) {
    return adminApi.request("delete", `api/admin/documents/${idFile}`);
  }

  updateName(file: IFile, action: TRadioButtonName) {
    const { id, name, parent_id, tags } = file;

    return adminApi.request(
      "put",
      `api/v2/admin/documents/${id}`,
      JSON.stringify({
        name,
        parent_id,
        action,
        document_tags: tags?.map((tag) => tag.id),
      }),
    );
  }

  // eslint-disable-next-line consistent-return
  updateHierarchy(
    parent: IFile,
    element: IFile | null,
    action: TRadioButtonName,
  ) {
    if (!_.isNull(element))
      return adminApi.request(
        "put",
        `api/admin/documents/${element.id}`,
        JSON.stringify({ name: element.name, parent_id: parent.id, action }),
      );
  }

  track(document_ids: number[], type: "download" | "share") {
    return api.request(
      "post",
      "api/track",
      JSON.stringify({
        type,
        document_ids,
      }),
    );
  }

  pasteFile(
    id_file: number,
    parent_id: number | null,
    action: TRadioButtonName,
    section_id?: number,
  ) {
    return adminApi.request("post", `api/admin/documents/${id_file}`, {
      parent_id,
      section_id,
      action,
    });
  }

  getLastEditedFiles() {
    return api.request(
      "get",
      `api/v2/documents?orderBy=updated_at&orderDirection=desc&perPage=10&filters[only_files]=1`,
    );
  }

  showDocument(id: string) {
    return api.request("get", `api/documents/${id}`);
  }

  checkRepeatedFiles(id: number | null, names: string[]) {
    return adminApi.request(
      "post",
      `api/admin/documents/${id}/exists`,
      JSON.stringify(names),
    );
  }

  filterBy(parentId: number | string, query: string) {
    const url = parentId
      ? `api/admin/documents/${parentId}${query}`
      : `api/admin/documents/null${query}`;
    return adminApi.request("get", url);
  }

  getVersionsHistory(id: number) {
    return adminApi.request("get", `api/v2/admin/documents/${id}/versions`);
  }

  restoreVersion(documentId: number, versionId: number) {
    return adminApi.request(
      "post",
      `api/v2/admin/documents/${documentId}/versions/${versionId}/restore`,
    );
  }

  searchBy(query: string, filter: string) {
    return adminApi.request(
      "get",
      `api/admin/documents/search?filters[${filter.toLowerCase()}]=${query}`,
    );
  }
}
