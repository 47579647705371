import React from "react";
import _ from "lodash";
import TextLink from "@components/TextLink";
import { useLocation } from "react-router-dom";
import useWindowSize from "@hooks/useWindowSize";

interface IFooterProps {
  link?: string;
  hideLink?: boolean;
}

const Footer = ({ hideLink, link }: IFooterProps) => {
  const location = useLocation();
  const { responsive } = useWindowSize();

  return (
    <div className="c-sso-footer o-ft-300 text-center">
      {!hideLink &&
        (_.isEqual(location.pathname, `/login/saml2`) && link ? (
          <div className="d-flex flex-row justify-content-center">
            {process.env.REACT_APP_LOGIN_SSO_TITLE === "KFC Plans" ||
            process.env.REACT_APP_LOGIN_SSO_TITLE === "Pizza Hut Plans" ? (
              <div className="px-5 my-4">
                <span className="c-sso-footer__bottom-label o-ft-base-700 mb-2">
                  Need access?
                </span>
                <span className="c-sso-footer__bottom-label o-ft-sm-400 d-inline-block">
                  If you already have an ID with{" "}
                  {process.env.REACT_APP_LOGIN_SSO_TITLE === "KFC Plans"
                    ? "KFC"
                    : "Pizza Hut"}{" "}
                  or another Yum! Brand, please{" "}
                  <TextLink
                    openInNewTab
                    type="href"
                    text="click here."
                    route={`https://iam.yum.com/uram/accessDetails?access=${
                      process.env.REACT_APP_LOGIN_SSO_TITLE === "KFC Plans"
                        ? "KFC%20Plan"
                        : "PH%20Plans"
                    }`}
                    smallFontSize
                  />
                </span>
                <hr
                  className={`mt-2 mb-3 opacity-100 o-cl-white ${responsive.md ? "o-cl-grey-100" : ""}`}
                />
                <span className="c-sso-footer__bottom-label o-ft-sm-400">
                  If you do not have an ID with{" "}
                  {process.env.REACT_APP_LOGIN_SSO_TITLE === "KFC Plans"
                    ? "KFC"
                    : "Pizza Hut"}{" "}
                  or another Yum! Brand, please{" "}
                  <TextLink
                    openInNewTab
                    type="href"
                    text="click here."
                    route={`https://iam.yum.com/uram/register?appName=${
                      process.env.REACT_APP_LOGIN_SSO_TITLE === "KFC Plans"
                        ? "kfcplan"
                        : "phplan"
                    }`}
                    smallFontSize
                  />
                </span>
              </div>
            ) : (
              <span className="c-sso-footer__bottom-label o-ft-lg-400">
                Need Access?{" "}
                <TextLink text="Request it here" type="href" route={link} />
              </span>
            )}
          </div>
        ) : (
          <TextLink
            text="Forgot password?"
            type="route"
            route="/forgot-password"
          />
        ))}
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/16434398"
          className="iubenda-embed iubenda-nostyle c-form-links__link o-ft-lg-400"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
        <span className="c-form-links__separator o-ft-xl"> | </span>
        <a
          href="https://www.iubenda.com/terms-and-conditions/16434398"
          className="iubenda-embed iubenda-nostyle c-form-links__link o-ft-lg-400"
          title="Terms and Conditions"
        >
          Terms and Conditions
        </a>
      </div>
    </div>
  );
};

export default Footer;
