/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-photo-view/dist/react-photo-view.css";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { PhotoProvider, PhotoView } from "react-photo-view";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "react-activity";
import { colors } from "@theme/colors";
import { cond } from "ramda";
import AdminHttpProvider, { baseURL, getCookie } from "@api/AdminHttpProvider";
import { isString } from "ramda-adjunct";
import { useDocumentSlice } from "../../stores/useDocumentSlice";
import { useShowDocument } from "@hooks/fetchs/useShowDocument";
import { useDownloadOriginalFiles } from "@hooks/useDownloadOriginalFiles";
import styled from "styled-components";
import generateThumbnail, {
  generatePdfThumbnail,
} from "@utils/generateThumbnail";
import { IDocumentFile } from "@models/files/File.type";
import axios from "axios";
import { urlToFile } from "@utils/utils";

const Iframe = styled.iframe`
  height: calc(100vh - 53px);
  width: 100%;
`;

const adminApi = new AdminHttpProvider();

function getFileExtension(filePath: string): string {
  const fileName = filePath.split("/").pop();
  if (fileName) {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex !== -1) {
      return fileName.slice(dotIndex + 1);
    }
  }
  return "";
}

const isImageFile = (extension: string): boolean => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];

  return imageExtensions.includes(extension);
};

const isVideoFile = (extension: string): boolean => {
  const videoExtensions = ["mp4", "webm"];

  return videoExtensions.includes(extension);
};

const isPdfFile = (extension: string): boolean => {
  return extension === "pdf";
};
const ShowDocument = () => {
  const { id } = useParams<{ id: string }>();
  const [doc, setDoc] = useState<any>();
  const [docName, setDocName] = useState<string | null>(null);
  const [extension, setExtension] = useState<string | null>(null);
  const [wasSendedThumbnail, setWasSendedThumbnail] = useState(false);
  const [file, setFile] = useState<IDocumentFile>();
  const { getData, loading, loadingDownload } = useShowDocument();
  const { setLastFile } = useDocumentSlice((state) => state);
  const [permission, setPermission] = useState<boolean>(true);
  const navigate = useNavigate();
  const { downloadFile } = useDownloadOriginalFiles();

  const createThumbail = async () => {
    if (file?.thumbnail !== null) return;
    let thumbnailBlob = null;
    if (doc.type !== "pdf") {
      const fileDoc = await urlToFile(file.url, `${doc.name}.${doc.type}`);
      thumbnailBlob = await generateThumbnail({ file: fileDoc });
    } else {
      thumbnailBlob = await generatePdfThumbnail(file.url);
    }
    await axios.put(file.temporary_thumbnail_upload_url, thumbnailBlob, {
      headers: {
        "Content-Type": thumbnailBlob?.type || "",
      },
    });
    if (doc) {
      await adminApi.request(
        "patch",
        `${baseURL}/api/v2/admin/documents/${doc.id}/thumbnail/uploaded`,
      );
    }
    setWasSendedThumbnail(true);
  };
  function loadDocument() {
    const bearerToken =
      localStorage.getItem("current_website") === "admin"
        ? getCookie("access_token")
        : localStorage.getItem("access_token");

    if (!bearerToken) {
      setLastFile(id as string);
      navigate("/login");
    }

    getData(id as string).then((e: any) => {
      if (e.status === 200) {
        setDoc(e.data);
        setFile(e.data.file);
        setDocName(`${e.data.name}.${e.data.type}`);
        setExtension(getFileExtension(e.data.file.path));
      } else {
        setPermission(false);
      }
    });
  }

  useEffect(() => {
    loadDocument();
  }, []);

  const onButtonClick = () => {
    if (id) {
      downloadFile(id);
    }
  };

  useEffect(() => {
    if (file && !wasSendedThumbnail) {
      createThumbail();
    }
  }, [file]);

  return (
    <div
      className={
        localStorage.getItem("current_website") === "admin"
          ? "o-admin-screen-container"
          : ""
      }
    >
      {permission ? (
        <div className="d-flex justify-content-center min-vh-100 align-items-center flex-column">
          {loading ? (
            <Spinner
              className="v-contacts__spinner"
              color={colors.brandColorSecondary}
            />
          ) : (
            file &&
            isString(file.url) &&
            isString(extension) && (
              <>
                {loadingDownload ? (
                  <Spinner
                    className="v-contacts__spinner"
                    color={colors.brandColorSecondary}
                  />
                ) : isPdfFile(extension) ? (
                  <div className="m-3 o-ft-sm-700 o-cl-grey-100 align-self-start">
                    {docName}
                  </div>
                ) : (
                  <div
                    className="o-cl-grey-100"
                    role="button"
                    onClick={onButtonClick}
                    aria-hidden
                  >
                    <i className="material-symbols-outlined me-2 o-cl-grey-100 o-ft-lg">
                      file_download
                    </i>
                    Download
                  </div>
                )}
                {cond([
                  [
                    (ext) => isImageFile(ext),
                    () => (
                      <div
                        style={{
                          height: "90vh",
                          display: "flex",
                        }}
                      >
                        <PhotoProvider>
                          <PhotoView src={file.url as string}>
                            <img
                              src={file.url}
                              alt=""
                              style={{ maxWidth: "75vw", maxHeight: "90vh" }}
                              className="img-thumbnail"
                              role="button"
                            />
                          </PhotoView>
                        </PhotoProvider>
                      </div>
                    ),
                  ],
                  [
                    (ext) => isVideoFile(ext),
                    () => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "75vw",
                          height: "90vh",
                        }}
                      >
                        <video
                          controls
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50vw",
                            height: "75vh",
                          }}
                        >
                          <source src={file.url} />
                        </video>
                      </div>
                    ),
                  ],
                  [
                    (ext: string) => isPdfFile(ext),
                    () => <Iframe src={file.url} title="description" />,
                  ],
                  [
                    () => true,
                    () => (
                      <div>
                        <DocViewer
                          style={{ width: "75vw", height: "90vh" }}
                          documents={[{ uri: file.url, fileType: extension }]}
                          pluginRenderers={DocViewerRenderers}
                          config={{
                            header: {
                              disableHeader: true,
                              disableFileName: true,
                              retainURLParams: false,
                            },
                            csvDelimiter: ",",
                            pdfZoom: {
                              defaultZoom: 1.1,
                              zoomJump: 0.2,
                            },
                            pdfVerticalScrollByDefault: true,
                          }}
                        />
                      </div>
                    ),
                  ],
                ])(extension as string)}
              </>
            )
          )}
        </div>
      ) : (
        <div className="d-flex justify-content-center min-vh-100 align-items-center flex-column">
          <div
            style={{
              color: "#565250",
              textAlign: "center",
              fontSize: "24px",
              lineHeight: "normal",
              fontWeight: 400,
              position: "relative",
            }}
          >
            Access Denied
          </div>
          <div
            style={{
              marginTop: "20px",
              color: "#565250",
              textAlign: "center",
              fontSize: "14px",
              lineHeight: "normal",
              fontWeight: 400,
            }}
          >
            It looks like you don&#039;t have permissions to access this file.
            Please contact your platform Administrator.
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowDocument;
