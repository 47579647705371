export default {
  brandColorPrimary: '#E21216',
  brandColorSecondary: '#E21216',
  brandColorTertiary: '#E21216',
  brandColorLight: '#E21216',


  brandColor: '#E21216',
  purpleLight: '#E21216',
}
