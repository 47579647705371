/* eslint-disable @typescript-eslint/no-explicit-any */
import DocumentController from "@controllers/DocumentController";
import FileController from "@controllers/FileController";
import { createAsyncThunk } from "@reduxjs/toolkit";

declare global {
  interface Window {
    Noty: any;
  }
}

export const getDocuments = createAsyncThunk(
  "documents/index",
  async (
    {
      parentId,
      query,
      only_folders,
    }: { parentId?: number; query?: string; only_folders?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const filters: { [key: string]: any } = {};
      if (parentId) filters.parent_id = parentId;
      if (query) filters.q = query;
      if (only_folders) filters.only_folders = only_folders;
      const response: any = await DocumentController.get(filters);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const showDocument = createAsyncThunk(
  "documents/show",
  async ({ id }: { id?: number }, { rejectWithValue }) => {
    try {
      const response: any = await DocumentController.show(id);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDocument = createAsyncThunk(
  "documents/update",
  async ({ id, params }: { id: number; params: any }, { rejectWithValue }) => {
    try {
      const response: any = await DocumentController.update(id, params);
      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createFolderFromDocumentsV2 = createAsyncThunk(
  "folder/create",
  async ({ params }: { params: any }, { rejectWithValue }) => {
    try {
      const response: any = await DocumentController.storeFolder(params);
      return response?.data?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addFileFV2 = createAsyncThunk(
  "file/add",
  async ({ params }: { params: any }, { rejectWithValue }) => {
    const { file, parentId, action, thumbnail } = params;

    try {
      const response: any = await FileController.addFile({
        file,
        parentId,
        action,
        thumbnail,
      });

      return response?.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
);
